import * as React from "react"
import Layout from "../components/layout"
const Introduction = () => {
    React.useEffect(() => {
        window.location.href = 'https://media.stonesoflight.org/introduction.pdf'
    }, [])
    return (
        <Layout>
            loading...
        </Layout>
    )
}

export default Introduction
